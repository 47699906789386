@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

/* global.css or styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure Ant Design styles are applied after Tailwind base styles */
/* @import 'antd/dist/antd.css'; */

@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-left {
  background: linear-gradient(180deg, #0017DE 0%, #0F1B84 100%);
  height: 100vh;
}

.login-text p {
  margin: 0px;
}

.main-logo {
  width: 219px;
  height: 96px;
  margin: 0px auto;
}

.main-logo img {
  width: 100%;
  height: 100%;
}

.logo-col {
  display: flex;
  justify-content: center;
}

.travel-logo {
  width: 443px;
  height: 418px;
  margin: 0px auto;
}

.travel-logo img {
  width: 100%;
  height: 100%;
}

.user-profile-btn svg {
  fill: white;
  font-size: 20px;
}

.trip-logo {
  width: 210px;
  min-width: 210px;
}

.userlist-table.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
}

.userlist-table {
  max-height: calc(100vh - 278px);
  overflow: auto;
}

.userlist-tabs-table {
  overflow: scroll;
  height: calc(100vh - 348px);
}

.ant-btn.ant-input-search-button,
.search-field .ant-btn.ant-input-search-button:hover,
.ant-switch.ant-switch-checked,
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: #000 !important;
}

.ant-menu.ant-menu-inline.ant-menu-light.sidebar-menu-active .ant-menu-item.ant-menu-item-selected {
  color: #000;
  font-weight: 600;
}

.sidebar-menu-active.ant-menu-inline.ant-menu-light .ant-menu-item {
  border-radius: 0;
  margin: 0;
  width: 100%;
  height: 50px;
}

.sidebar-menu-active.ant-menu-inline.ant-menu-light .ant-menu-item-selected,
.sidebar-menu-active .ant-menu-item:active,
.sidebar-menu-active .ant-menu-item:hover,
.sidebar-menu-active.ant-menu-inline.ant-menu-light .ant-menu-item-active {
  background-color: #f5f5f5 !important;
  border-radius: 0;
  margin: 0;
  width: 100%;
}

.sidebar-menu-active.ant-menu-inline.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

.ant-switch {
  background-color: #858383 !important;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover,
.ant-input-search .ant-input:hover,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.ant-select.ant-select-lg.ant-select-outlined:hover .ant-select-selector {
  border-color: #000 !important;
}

.ant-pagination .ant-pagination-item-active a,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.ant-pagination .ant-pagination-item-active a:hover {
  color: #000;
}

.wave-motion.wave-motion-appear-active {
  --wave-color: none;
}

.ant-input-outlined:focus,
.ant-input-outlined:hover,
.ant-select-selector:hover,
.ant-select-focused.ant-select-outlined .ant-select-selector {
  border-color: #000 !important;
  box-shadow: none !important;
}

.otp-box .ant-otp input {
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 18px;
}

.otp-box .ant-otp {
  justify-content: space-between;
  display: flex;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #000;
  background-color: #000;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #000;
}

.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal {
  margin: 0px !important;
  top: 0px !important;
}

/* .ant-modal-content {
  padding: 0px !important;
} */

.image-gallery .ant-modal-close {
  top: -32px !important;
  right: -32px !important;
}

.image-gallery .anticon-close svg {
  fill: white;
}

.ant-drawer-close .anticon-close svg {
  fill: rgb(0, 0, 0);
}

.ant-table-cell {
  font-size: 16px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
  text-shadow: none;
  font-weight: 500;
}

.ant-tabs-tab-btn {
  font-size: 15px;
  color: #00000073;
  font-weight: 500;
}

.ant-tabs-tab-btn:hover,
.ant-tabs .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-tab-btn:active {
  color: #000 !important;
}

.ant-rate-star.ant-rate-star-full svg {
  fill: #000;
}

.ant-rate-star.ant-rate-star-zero svg {
  fill: #e5e5e5;
}

.ant-modal {
  top: 25%;
}

.image-gallery .ant-modal .ant-modal-close {
  right: -5px;
  top: -36px;
  inset-inline-end: 0;
  color: white;
}

.ant-modal .ant-modal-close:hover {
  background-color: transparent;
}

.interests-table .ant-table-container {
  max-height: calc(100vh - 303px);
  overflow: auto;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  border-radius: 14px;
  border: 3px solid var(--primary);
}

.ant-table-wrapper .ant-table-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.ant-table-wrapper .ant-table-body::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

.ant-table-wrapper .ant-table-body::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  border-radius: 14px;
  border: 3px solid var(--primary);
}

.auto-password label {
  width: 100%;
}

.custom-quill .ql-toolbar {
  border: 1px solid #e5e7eb;
  border-radius: 8px 8px 0px 0px;
}

.custom-quill .ql-container {
  border: 1px solid #e5e7eb;
  border-top: none;
  border-radius: 0 0 8px 8px;
  min-height: 150px;
  background-color: #fff;
}

.custom-quill .ql-editor {
  font-family: 'jost';
  padding: 0px;
}

.ql-tooltip {
  left: 0px !important;
}

.custom-quill {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
}

.custom-quill .ql-toolbar {
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9 !important;
  padding: 8px;
}

.custom-quill .ql-toolbar .ql-formats button,
.custom-quill .ql-toolbar .ql-formats .ql-picker {
  border: none;
  background: #fff;
  color: #333;
  border-radius: 4px;
  margin-right: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-quill .ql-toolbar .ql-formats button:hover,
.custom-quill .ql-toolbar .ql-formats .ql-picker:hover {
  background: #e0e0e0;
  color: #000;
}

.custom-quill .ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-quill .ql-toolbar .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #333;
  padding: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-quill .ql-toolbar .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  background: #f0f0f0;
  color: #000;
}

.custom-quill .ql-container {
  padding: 10px;
  min-height: 200px;
  background: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-quill .ql-container:focus-within {
  border-color: #b4b4b4;
}

.custom-quill .ql-container .ql-editor::-webkit-scrollbar {
  width: 8px;
}

.custom-quill .ql-container .ql-editor::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4px;
}

.custom-quill .ql-container .ql-editor::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  border-radius: 4px;
  border: 2px solid #f5f5f5;
}

.custom-quill .ql-toolbar.ql-snow .ql-formats {
  margin: 5px;
}

.phone-input .ant-input {
  height: 48px;
}

.dropdown-max-content {
  width: max-content !important;
  min-width: 320px;
}

.dropdown-max-content .ant-select-item-option-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.country-code .ant-select-selection-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.auto-password label {
  width: 100%;
}

.custom-modal-title {
  font-size: 20px;
}

.ant-drawer-close {
  order: 2;
}

.image-gallery .ant-modal-content {
  padding: 0px;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #d9d9d9 !important;
}

.custom-tabs-col .ant-tabs {
  width: 100%;
}

.custom-tabs-col .ant-tabs-nav {
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
}

.custom-tabs-col .ant-tabs-tab.ant-tabs-tab-active {
  border: 2px solid black !important;
  color: white;
  background-color: #f3f3f3;
}

.custom-tabs-col .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.custom-tabs-col .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
  color: #000 !important;
  font-weight: 600 !important;
}

.custom-tabs-col .ant-tabs-nav::before {
  border-bottom: none !important;
}

.custom-tabs-col .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: transparent !important;
}

.ant-tabs-nav .ant-tabs-nav-wrap {
  display: block !important;
  overflow: visible !important;
  white-space: normal !important;
}

.custom-tabs-col .ant-tabs-nav .ant-tabs-nav-more {
  padding: 8px 10px !important;
}

.custom-tabs-col .ant-tabs-nav-list {
  display: block !important;
}

.custom-tabs-col .ant-tabs-content {
  background-color: #f9fafb;
}

.ant-card-head-title {
  font-size: 20px;
  font-weight: 600;
}

.custom-tabs-col .ant-tabs-nav-list .ant-tabs-tab {
  width: max-content;
  justify-content: center;
  min-width: 290px;
  margin: 0px 15px 15px 0px;
  border: 2px solid #e5e7eb;
}


.map-col .ant-card-body {
  height: 100%;
}

.platform-input .ant-col {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.platform-input .ant-form-item {
  width: 100%;
}

.general-setting-input label {
  height: 100% !important;
}

.multiple-country .ant-select-selector {
  min-height: 48px;
}

.setting-input label {
  font-size: 16px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.complete-profile-email .ant-input {
  height: 48px;
}

.ant-spin .ant-spin-dot-item {
  background-color: #000 !important;
}

.ant-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.ant-empty .ant-empty-image {
  margin-bottom: 6px;
}

.ant-empty .ant-empty-description {
  font-size: 14px;
}

.ant-card .ant-card-head {
  border-bottom: 1px solid rgb(209 213 219);
}

.trip-card .ant-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.check-box .ant-checkbox-checked .ant-checkbox-inner,
.check-box .ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #000 !important;
  border-color: #000 !important;
}

.check-box .ant-checkbox .ant-checkbox-inner {
  border-color: #000 !important;
}

.non-interactive-checkbox {
  pointer-events: none;
}

.ant-drawer-open {
  background-color: #00000036;
}

.disable-clicks {
  pointer-events: none;
  user-select: none;
}

.review-icon-color .ant-rate-star.ant-rate-star-full svg {
  fill: gold;
}

.agent-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
}

.gallery-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.userlist-table thead th,
.userlist-tabs-table thead th {
  cursor: default;
}

.word-wrap-column {
  white-space: normal;
  word-wrap: break-word;
  max-width: 200px;
}

.user-name {
  border-bottom: 1px solid #ccc;
}